import React, { useState, useEffect } from "react";
import ListNotes from "../noteList/ListNotes";
import InputPatient from "../noteList/InputPatient";
import SideBarV2 from "../SideBarV2/SideBarV2";
import Calendar from "../schedule/Calendar";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import userflow from "userflow.js";
import jwt_decode from "jwt-decode";
import ApptDatePicker from "../schedule/ApptDatePicker"; // ***DO NOT DELETE***

//Dashboard is the Home Page where doctors can view/add patients to a schedule, view/edit notes, and delete patients.
const Dashboard = ({ setAuth, setIsSubscribed, isSubscribed }) => {
  // Function to format the date in the desired format
  const formatDate = (date) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const [name, setName] = useState("");
  const [turnaroundTime, setTurnaroundTime] = useState("");
  const [patientsChange, setPatientsChange] = useState(false);
  const [allPatients, setAllPatients] = useState([]);
  const [pickedDate, setPickedDate] = useState(formatDate(new Date()));
  const [org, setOrg] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const openSidebar = () => setIsSidebarOpen(true);
  const closeSidebar = () => setIsSidebarOpen(false);

  useEffect(() => {
    //Retrieve name of the doctor from database
    async function getName() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/dashboard/`,
          {
            method: "GET",
            headers: { token: localStorage.token, pickedDate: pickedDate },
          }
        );

        const parseRes = await response.json();

        setName(parseRes[0].user_name);
        setTurnaroundTime(parseRes[0].turnaround_time);
        setOrg(parseRes[0].organization);
        setIsLoading(false);

        //sets all the patients associated with that doctor
        setAllPatients(parseRes);
      } catch (err) {
        console.error(err.message);
      }
    }
    getName();

    setPatientsChange(false);
    setPickedDate(pickedDate);
    //getCurrentDate();
    window.localStorage.setItem("date", JSON.stringify(pickedDate));

    let intervalId;

    if (!isEditing) {
      intervalId = setInterval(getName, 5000);
      return () => clearInterval(intervalId);
    }
  }, [patientsChange, pickedDate, isEditing]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/auth/subscription-status`,
          {
            method: "GET",
            headers: { token: localStorage.token },
          }
        );

        const parseRes = await response.json();

        setIsSubscribed(parseRes.is_subscribed);
      } catch (err) {
        console.error("Error fetching user info:", err.message);
      }
    })()
  }, [isSubscribed])

  // to grab email and name when they load the page initially
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/dashboard/userinfo`,
          {
            method: "GET",
            headers: { token: localStorage.token },
          }
        );

        const parseRes = await response.json();
        const { user_id, user_name, user_email, created_at } = parseRes[0];

        userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);
        userflow.identify(user_id, {
          name: user_name,
          email: user_email,
          signed_up_at: created_at
        });
      } catch (err) {
        console.error("Error fetching user info:", err.message);
      }
    })()

  }, [])


  /* parseRes is how we get the notes list.
     right now its being displayed when current date is loaded.  when calendar componet changes dates, it doesn't load the
     new date's notes list. if i add on another date it also just adds to the current date. so need a way to update the stated
     to match the current selected date. */

  // const getCurrentDate = (separator = "/") => {
  //   let newDate = new Date();
  //   let date = newDate.getDate();
  //   let month = newDate.getMonth() + 1;
  //   let year = newDate.getFullYear();

  //   let result = `${
  //     month < 10 ? `0${month}` : `${month}`
  //   }${separator}${date}${separator}${year}`;
  //   console.log(result);

  //   setTodayDate(result);
  // };


  return (
    <div className="flex mt-5 laptop:mt-0 ml-10 desktop:ml-dashboard-left">
      <SideBarV2
        isOpen={isSidebarOpen}
        onClose={closeSidebar}
        setAuth={setAuth}
      />
      {!isLoading ? (
        <div className="flex flex-col transition-margin">
          <button
            onClick={openSidebar}
            className="text-5xl self-start text-accent2 rounded desktop:hidden"
          >
            ☰
          </button>
          <div className="flex text-4xl font-bold text-accent1 mt-5">
            <h1>Hi there, {name} 👋</h1>
          </div>
          <div className="mt-3 mb-3">
            <Calendar
              pickedDate={pickedDate}
              setPickedDate={setPickedDate}
              formatDate={formatDate}
            />
          </div>
          {pickedDate != null && (
            <InputPatient
              pickedDate={pickedDate}
              setPatientsChange={setPatientsChange}
              patientsChange={patientsChange}
            ></InputPatient>
          )}
          <ListNotes
            isLoading={isLoading}
            allPatients={allPatients}
            setPatientsChange={setPatientsChange}
            name={name}
            turnaroundTime={turnaroundTime}
            org={org}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
            pickedDate={pickedDate}
          ></ListNotes>
        </div>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
};

export default Dashboard;
