import React, { Fragment, useState, useRef, useEffect } from "react";

// import css from "./noteList.module.css";
import jwt_decode from "jwt-decode";
import axios from "axios";
//Functionality to add a patient for a doctor
const InputPatient = ({ setPatientsChange, pickedDate, patientsChange }) => {
  const [description, setDescription] = useState("");
  const [patientData, setPatientData] = useState({});
  const [importType, setImportType] = useState("");
  const [appointmentID, getAppointmentID] = useState("");
  const [patientType, setPatientType] = useState("");

  //const [dateOfBirth, setDateOfBirth] = useState("");
  const [MRN, setMRN] = useState("");
  const inputRef = useRef(null);

  const onSubmitForm = async (e) => {
    //var newDOB = formatDate(dateOfBirth);
    e.preventDefault();
    try {
      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);
      const body = {
        description,
        pickedDate,
        MRN,
        patientData,
        importType,
        appointmentID,
        patientType,
      };
      await fetch(`${process.env.REACT_APP_API_URL}/dashboard/todos`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body),
      });

      // logging
      // get user_id from token
      const decoded_token = jwt_decode(localStorage.token);
      const user_id = decoded_token["user"]["id"];
      // User clicked upload
      window.gtag("event", "user_clicked_add_patient", {
        app_name: "Knowtex-Webapp",
        screen_name: "DashBoard",
        job_name: user_id,
      });

      // Define the event
      const userEvent = {
        env: process.env.REACT_APP_API_URL.includes("localhost")
          ? "local"
          : "prod",
        service: "knowtex-webApp",
        event: {
          eventName: "user_clicked_add_patient",
          userId: user_id,
          patientName: description,
          screenName: "DashBoard",
          timestamp: new Date().toISOString(),
        },
      };
      const endpointUrl =
        "https://tpv4xmll56.execute-api.us-east-1.amazonaws.com/prod/doctorservice";
      axios
        .post(endpointUrl, userEvent)
        .then((response) => {
          // console.log("Event sent successfully:", response.data);
        })
        .catch((error) => {
          // console.error("Failed to send event:", error);
        });

      setPatientsChange(true);
      setDescription("");
      //setDateOfBirth("");
      setMRN("");
      focus();
    } catch (err) {
      console.error(err.message);
    }
  };
  const focus = () => {
    inputRef.current.focus();
  };
  //changes date of birth into mm/dd/yy
  // const formatDate = (date) => {
  //   var datePart = date.match(/\d+/g),
  //     year = datePart[0].substring(0, 4), // get four digits
  //     month = datePart[1],
  //     day = datePart[2];
  //   return month + "/" + day + "/" + year;
  // };

  // TODO: update useEffect so that a successfully delete alert appears
  // useEffect(() => {
  //   // This effect will run when patientsChange is updated
  //   if (patientsChange) {
  //     // Display a success alert using window.alert
  //     window.alert("Patient added successfully!");

  //     // Reset patientsChange to false
  //     setPatientsChange(false);
  //   }
  // }, [patientsChange]);

  return (
    <Fragment>
      <form role="form" className="flex" onSubmit={onSubmitForm}>
        <div className="relative flex items-center mr-3 ">
          <input
            type="text"
            placeholder="Patient Name"
            className="border-0 focus:placeholder-white text-knowtex-grey shadow-knowtex-shadow rounded-lg pl-10 pr-4 py-2 mr-4"
            value={description}
            ref={inputRef}
            onChange={(e) => setDescription(e.target.value)}
            style={{ width: "25vw" }}
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              className="w-6 h-6 text-light-grey focus:placeholder-white "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M16 12h4m-2 2v-4M4 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1c0 .6-.4 1-1 1H5a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
          </div>
        </div>
        <div className="relative flex items-center  mr-10">
          <input
            type="text"
            placeholder="MRN"
            className="focus:placeholder-white border-0 text-knowtex-grey rounded-lg shadow-knowtex-shadow pl-10 pr-4 py-2"
            value={MRN}
            onChange={(e) => setMRN(e.target.value)}
            style={{ width: "20vw" }}
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 ">
            <svg
              className="w-6 h-6 text-light-grey"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 4h3c.6 0 1 .4 1 1v15c0 .6-.4 1-1 1H6a1 1 0 0 1-1-1V5c0-.6.4-1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z"
              />
            </svg>
          </div>
        </div>

        <button
          className={`hover:cursor-pointer py-2 px-8 rounded-md text-background1 ${
            !description || !MRN
              ? "bg-transparent-grey text-background1 cursor-not-allowed"
              : "bg-accent1"
          }`}
          disabled={!description || !MRN}
          style={{ width: "auto" }}
          roll="button"
        >
          Add Patient
        </button>
      </form>
    </Fragment>
  );
};

export default InputPatient;
